import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';
import { Link } from 'gatsby';

import WigLoader from '@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader';

import '@accrosoft-ltd/vf-careers-site-theme/src/css/wig-bundle.css';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';

import fetchJsonp from 'fetch-jsonp';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '@accrosoft-ltd/vf-careers-site-theme/src/components/analytics';
import { useTracking } from 'react-tracking';

require('es6-promise').polyfill();

export default function StandardPagePage(props) {
  const {
    pageContext: {
      integratorData,
      contentMetaData,
      contentDataData,
      config,
      navResultData,
    },
  } = props;

  const siteConfig = config;

  const [apiKey, setApiKey] = useState(siteConfig.apiKey || props.smartfeedID);
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group ? 'group' : 'id'
  );
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking(
    { page: 'Homepage', location: props.location, apiKey: props.apiKey },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies['ap-signals-user-id'],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      props.location,
      cookies['ap-signals-user-id'],
      sessionIDValue,
      siteConfig
    );
  }, []);

  useEffect(() => {
    if (apiKey) {
      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            'GetCompanyDetails' +
            '?' +
            groupOrIdParam +
            '=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                apiKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                setSmartfeedCompanyData(jsonList);
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey, groupOrIdParam]);

  const returnParentArray = (id) => {
    if (id) {
      if (
        navResultData &&
        navResultData.menu &&
        navResultData.menu.length > 0
      ) {
        let parentArrayData = navResultData.menu.filter(function (item) {
          return item.id === id;
        });

        return parentArrayData[0];
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  let pageParentDataArray = {};

  if (contentMetaData && contentMetaData.parent && contentMetaData.parent.id) {
    pageParentDataArray = returnParentArray(contentMetaData.parent.id);
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  return (
    <Layout
      trackEvent={trackEvent}
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      path={siteConfig.companyCareersSite ? '/' : '/'}
      navResultData={navResultData}
      contentDataData={contentDataData}
      integratorData={integratorData}
      contentMetaData={contentMetaData}
      {...props}
    >
      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          <div
            className="banner page"
            style={{
              backgroundImage: `              
              url("${
                contentDataData[0].children[0] &&
                contentDataData[0].children[0].value
                  ? returnResizedImageURL(
                      { w: 1900 },
                      contentDataData[0].children[0].value
                    )
                  : returnResizedImageURL({ w: 1900 }, '/banner.png')
              }")`,
            }}
          >
            <div className="container">
              <div className="bannerWrapper">
                <div className="breadcrumb">
                  <Link to="/">Home</Link> /{' '}
                  <span className="active">{contentMetaData.title}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="white-fullwidth">
            <div className="mainContentWrapper page">
              <section className="app-content">
                <div className="container">
                  <div className="row">
                    <div className="col-12 pageMain">
                      <h1>{contentDataData[0].children[1].value}</h1>
                    </div>
                  </div>
                </div>

                {contentDataData[1].children[0].children.map((section, i) => (
                  <div
                    className="content-section"
                    style={{ backgroundColor: i % 2 !== 0 && '#fff' }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <WigLoader
                            key={i}
                            weducWebsitesID={config.weducWebsitesID}
                            weducContainer={config.weducContainerID}
                            weducEntity={config.weducEntityID}
                            customDataArray={
                              contentDataData[1].children[0].children[i][0]
                                .value
                            }
                            contentDataData={contentDataData[1].children}
                            contentMetaData={contentMetaData}
                            contentDataArray="0"
                            contentDataChild={i.toString()}
                            AccroPressProdEndpoint={
                              config.AccroPressProdEndpoint
                            }
                            AccroPressLocalEndpoint={
                              config.AccroPressLocalEndpoint
                            }
                            integratorID={config.integratorID}
                            integratorAccessToken={config.integratorAccessToken}
                            imageFormatting={
                              process.env.IMAGE_FORMATTING_SECRET ||
                              process.env.GATSBY_IMAGE_FORMATTING_SECRET
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
}
